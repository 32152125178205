import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { TeamPage } from "./pages/TeamPage";
import { PricingPage } from "./pages/PricingPage";
import { CareerPage } from "./pages/CareerPage";

function RedirectToMeet() {
  useEffect(() => {
    window.open(
      "https://calendly.com/abdul-raheem-devsterlabs/30min",
      "_self"
    );
  }, []);

  return <h1>Redirecting...</h1>; // Optionally show a message
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/career" element={<CareerPage />} />
      <Route path="/meet" element={<RedirectToMeet />} />
    </Routes>
  );
}

export default App;
