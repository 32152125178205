export const de = {
  "Why should you work with us?": "Warum sollten Sie mit uns arbeiten?",
  "Join the team": "Treten Sie dem Team bei",
  "Apply now to get registered in our talent pool":
    "Bewerben Sie sich jetzt und werden Sie in unseren Talentpool aufgenommen",
  "Leader in the quality of outsourcing services":
    "Führend in der Qualität von Outsourcing-Dienstleistungen",
  "Gateway to pre-screened,": "Tor zu vorab",
  "full-time": "geprüften",
  "software developers": "Vollzeit-Softwareentwicklern",
  scroll: "scrollen",
  "The most trusted": "Der vertrauenswürdigste",
  "outsourcing partner in Pakistan": "Outsourcing-Partner in Pakistan",
  "waiting for you in our team": "erwartet Sie in unserem Team",
  "Leave your CV and our manager will contact you soon":
    "Hinterlassen Sie Ihren Lebenslauf und unser Manager wird Sie in Kürze kontaktieren",
  "Send resume": "Lebenslauf senden",
  "Privacy Policy": "Datenschutzrichtlinie",
  HIRING: "HIRING",
  VACANCIES: "STELLENANGEBOTE",
  "About Us": "Über uns",
  Benefits: "Vorteile",
  Team: "Team",
  Partners: "Partner-Angebote",
  "Contact Us": "Kontaktieren Sie uns",
  "Founders of": "Gründer von",
  "Data Annotator": "Datenannotator",
  "Senior React Native Developer": "Senior React Native Entwickler",
  "Backend Developer": "Backend-Entwickler",
  "Senior Data Scientist (GenAI, LLM)": "Senior Data Scientist (GenAI, LLM)",
  "ML Researcher": "Forscher für maschinelles Lernen",
  "Founder & Chief Executive Officer": "Gründer und CEO",
  "Co-founder & Chief Operating Officer":
    "Mitgründer und Chief Operating Officer",
  "View All Benefits": "Alle Vorteile anzeigen",
  "Transparent Pricing": "Transparente Preise",
  "No hidden fees—what you see is what you get": "Keine versteckten Gebühren – was Sie sehen, ist, was Sie bekommen",
  "Tax-Free Services": "Steuerfreie Dienstleistungen",
  "No need to worry about extra tax hassles": "Sie müssen sich keine Sorgen über zusätzlichen Steueraufwand machen",
  "Compliance-Free Solution": "Compliance-freie Lösung",
  "No dealing with local employment laws; we manage the legalities": "Sie müssen sich nicht mit dem örtlichen Arbeitsrecht auseinandersetzen; wir kümmern uns um die rechtlichen Aspekte",
  "Flexible Contracts": "Flexible Verträge",
  "Easily adaptable terms to meet your policies": "Leicht anpassbare Bedingungen zur Einhaltung Ihrer Richtlinien",
  "Cost Savings": "Kosteneinsparungen",
  "Reduce expenses by up to 30% compared to local hires": "Reduzieren Sie die Kosten um bis zu 30 % im Vergleich zu lokalen Einstellungen",
  "Dedicated Focus": "Konzentrierter Fokus",
  "Our developers are fully dedicated to your projects": "Unsere Entwickler widmen sich voll und ganz Ihren Projekten",
  "Seamless Replacements": "Nahtloser Ersatz",
  "If a developer isn’t a perfect fit, we replace them instantly": "Wenn ein Entwickler nicht perfekt passt, ersetzen wir ihn sofort",
  "Managed Payroll": "Verwaltete Gehaltsabrechnung",
  "We handle the payroll so you can focus on growth": "Wir kümmern uns um die Gehaltsabrechnung, damit Sie sich auf Ihr Wachstum konzentrieren können",
  "Pakistan Office Access": "Zugang zum Büro in Pakistan",
  "Establish a local presence with our office resources": "Bauen Sie mit unseren Büroressourcen eine lokale Präsenz auf",
  "24/7 Backup Availability": "Backup-Verfügbarkeit rund um die Uhr",
  "People in the team": "Personen im Team",
  "Operational in Germany, United States and Pakistan (Now Expanding)": "In Deutschland, den USA und Pakistan aktiv (wird derzeit erweitert)",
  "3 countries": "3 Länder",
  "People in the talent pool": "Personen im Talentpool",
  "Job Success Score": "Job-Erfolgs-Score",
  "Need a backup developer? We got you": "Brauchen Sie einen Backup-Entwickler? Wir haben ihn"
};
