export const en = {
  "Why should you work with us?": "Why should you work with us?",
  "Join the team": "Join the team",
  "Apply now to get registered in our talent pool":
    "Apply now to get registered in our talent pool",
  "Leader in the quality of outsourcing services":
    "Leader in the quality of outsourcing services",
  "Gateway to pre-screened,": "Gateway to pre-screened,",
  "full-time": "full-time",
  "software developers": "software developers",
  scroll: "scroll",
};
